export function bulma_calendar_options_disabled_dates_inner(current_users_group, day, editing, date_local) {
    const calendar_date = new Date(day);
    let year_offset = (calendar_date.getMonth() < 6) ? 0 : 1;
	//temporarily setting to false
	editing = false;
    if (editing && date_selectable(calendar_date.getFullYear(), calendar_date.getMonth(), current_users_group)) {
      if ((date_local.getFullYear() + year_offset) === calendar_date.getFullYear()) {
        return false;
      } else {
        return true;
      }
    } else {
      return !date_selectable(calendar_date.getFullYear(), calendar_date.getMonth(), current_users_group);
    }
}


// This function is expected to mimic the Ruby function of the same name.
export function date_selectable(year_index, month_index, current_users_group) {
    const current_date = new Date(year_index, month_index, 1);
	const date_local = new Date();
	date_local.setHours(0, 0, 0, 0);
    let year_offset = (current_date.getMonth() < 6) ? 0 : 1;
    let calculation_number = 999999;
	if (current_date <= date_local) {
		switch (current_users_group) {
			case '0':
			//called from clinical case entry
			//hardcoding for now; future-proof later
			if (current_date.getFullYear() + year_offset == 2025) {
				calculation_number = 0;
			}
			break;
			case '1':
			//calculation_number = (current_date.getFullYear() + year_offset - 2002) % 3;
			//hardcoding for now; future-proof later
			if ((current_date.getFullYear() + year_offset) >= 2024 && (current_date.getFullYear() + year_offset) <= 2026) {
				calculation_number = 0;
			}
			break;
			case '2':
			//calculation_number = (current_date.getFullYear() + year_offset - 2001) % 3;
			//hardcoding for now; future-proof later
			if ((current_date.getFullYear() + year_offset) >= 2023 && (current_date.getFullYear() + year_offset) <= 2025) {
				calculation_number = 0;
			}
			break;
			case '3':
			//calculation_number = (current_date.getFullYear() + year_offset - 2000) % 3;
			//hardcoding for now; future-proof later
			if ((current_date.getFullYear() + year_offset) >= 2025 && (current_date.getFullYear() + year_offset) <= 2027) {
				calculation_number = 0;
			}
			break;
		}
	}
    return calculation_number === 0;
}
