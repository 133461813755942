// import bulmaCalendar from "../utilities/bulma/bulma-calendar"
import bulmaCalendar from "../utilities/bulma/bulma-calendar_6_1_18"
import { date_selectable, bulma_calendar_options_disabled_dates_inner } from "../packs/application_functions";
$(document).on(('turbo:load'), () => {
  if ($('#professional_course_start_date_of_event').length) {
    let default_date = new Date();
    let date_val = $("#professional_course_start_date_of_event").val();
    const editing = $('#editing_record')[0];
    if (!date_val) date_val = $("#professional_course_start_date_of_event")[0].getAttribute('value');
    if (!date_val) {
      for (let i = 0; i <= 24; i++) {
        if (date_selectable(default_date.getFullYear(), default_date.getMonth(), document.getElementById('professional_course_professional_category_id').value)) {
          date_val = default_date;
        } else {
          default_date.setMonth(default_date.getMonth() + 1)
        }
        if (date_val) {
          break;
        }
      }
    }
    if (date_val.constructor.name === 'String') {
      const date = new Date();
      const split_date_string = date_val.split('-');
      date.setFullYear(split_date_string[0]);
      date.setMonth(split_date_string[1]);
      date.setDate(split_date_string[2]);
      date_val = date;
    }
    let offset = default_date.getTimezoneOffset()
    const date_local = new Date();
	/*
    if (editing) {
      const split_date_string = editing.dataset.courseStartDate.substring(0, editing.dataset.courseStartDate.search(' ')).split('-');
      date_local.setFullYear(split_date_string[0]);
      date_local.setMonth(split_date_string[1] - 1);
      date_local.setDate(split_date_string[2]);
      Array.from(document.getElementsByClassName('invalid_date_warning')).forEach((e) => e.classList.toggle('is-hidden', date_selectable(date_local.getFullYear(), date_local.getMonth(), document.getElementById('professional_course_professional_category_id').value)));
    }
	*/
    let options = {
      startDate: date_val,
      "color": "#000858",
      "dateFormat": "MM/dd/yyyy",
      "showHeader": false,
      "showButtons": false,
      "showFooter": false,
      "showClearButton": false,
      disabledDates: function(date, day) {
        return bulma_calendar_options_disabled_dates_inner(document.getElementById('professional_course_professional_category_id').value, day, editing, date_local);
    }}
    const calendars = bulmaCalendar.attach('[type="date"]', options);
    $("#professional_course_start_date_of_event").val(new Date(date_val + offset));
    var element = calendars[0];
    if (element) {
      $(".datetimepicker-clear-button").hide();
      element.on('select', function(datepicker) {
        $("#professional_course_start_date_of_event").trigger("change");
        $("#professional_course_start_date_of_event").val(datepicker.data.value());
      });
    }
  }
})