import $ from 'jquery';
import 'selectize';
// import bulmaCalendar from "../utilities/bulma/bulma-calendar"
import bulmaCalendar from "../utilities/bulma/bulma-calendar_6_1_18"
import { date_selectable, bulma_calendar_options_disabled_dates_inner } from "../packs/application_functions";


$(document).on('turbo:before-cache', function() {
  $('.selectized').each(function(){
    if (this.selectize != undefined) {
      this.selectize.destroy()
    }
  })
});

$(document).on(('turbo:load'), () => {

  if ($('#clinical_case_case_date').length) {
    $("#clinical_school_block").hide()
    const editing = $('#editing_record')[0];
    let default_date = new Date();
    const date_val = $("#clinical_case_case_date").val();
    if(date_val && date_val != ""){
      default_date = new Date($("#clinical_case_case_date").val())
    }
    let offset = default_date.getTimezoneOffset()
    const date_local = new Date();
	/*
    if (editing) {
      const split_date_string = editing.dataset.courseStartDate.substring(0, editing.dataset.courseStartDate.search(' ')).split('-');
      date_local.setFullYear(split_date_string[0]);
      date_local.setMonth(split_date_string[1] - 1);
      date_local.setDate(split_date_string[2]);
      Array.from(document.getElementsByClassName('invalid_date_warning')).forEach((e) => e.classList.toggle('is-hidden', date_selectable(date_local.getFullYear(), date_local.getMonth(), 0)));
    }
	*/
    let options = {
      "minDate": new Date(2020,1,1),
      "startDate": (new Date(default_date.getTime() + (offset * 1000 * 60))),
      "color": "#000858",
      "dateFormat": "MM/dd/yyyy",
      "showHeader": false,
      "showButtons": false,
      "showFooter": false,
      "showClearButton": false,
      disabledDates: function(date, day) {
        return bulma_calendar_options_disabled_dates_inner('0', day, editing, date_local);
      }}
    const calendars = bulmaCalendar.attach('[type="date"]', options);
    var element = calendars[0];
    $("#clinical_case_case_date").val(new Date(default_date + offset));
    if (element) {
      $(".datetimepicker-clear-button").hide()
      // bulmaCalendar instance is available as element.bulmaCalendar
      element.on('select', function(datepicker) {
        $("#clinical_case_case_date").val(new Date(datepicker.data.value() + " 00:00:00 " + new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]))
        if (
          $("#clinical_case_case_date").val() != "" &&
          $("#clinical_case_surgeon").val() != "" &&
          $("#clinical_category_depth_0").val() != "") {
            $("#next-button, #case_details_save_add").prop("disabled", false)
        } else if (
          $("#clinical_case_case_date").val() == "" &&
          $("#clinical_case_surgeon").val() == "" &&
          $("#clinical_category_depth_0").val() == "")
        {
          $("#next-button, #case_details_save_add").prop("disabled", true)
        }

      });
    }
  }

  var clinical_category_depth_0, $clinical_category_depth_0;

  var clinicalCategoriesDepth0 = []


  if ($('#clinical_category_depth_0').length) {
    $.ajax({
      type: "GET",
      url: "/clinical_categories",
      success: function(response) {
        clinicalCategoriesDepth0 = []
        response.data.forEach(function(category) {
          clinicalCategoriesDepth0.push({
            id: category.id,
            name: category.name
          })
        });


        $('#clinical_category_depth_0').removeAttr("disabled");
        $clinical_category_depth_0 = $('#clinical_category_depth_0').selectize({
          create: false,
          sortField: "id",
          valueField: 'id',
          maxItems: 1,
          labelField: 'name',
          searchField: ["name"],
          options: clinicalCategoriesDepth0,
          render: {
            item: function(item, escape) {
                return '<div>' +
                  (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '')
                '</div>';
            },
            option: function(item, escape) {
                var label = item.name
                return '<div class="container">' +
                    '<div class="has-text-weight-bold">' + escape(label) + '</div>' +
                '</div>';
            }
          },
          onChange: function(value) {
            var selectedCategory = null;
            clinicalCategoriesDepth0.forEach(function(category) {
              if(value == category.id)
              {
                selectedCategory = category;
              }
            })

            if (!value.length && !selectedCategory) return;

            if(selectedCategory.name == "2P - Instructor") {
              $("#clinical_case_clinical_school").removeAttr("disabled");
              $("#clinical_school_block").show()
            }
            else {
              $("#clinical_case_clinical_school").prop("disabled", true);
              $("#clinical_school_block").hide()
            }
          },
        })
      }
    })

    //clinical_category_depth_0 = $clinical_category_depth_0[0].selectize;
  }

});
